import React from "react";

function StarSubmit() {
  return (
    <div className="StarSubmit icon">
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.00043 13.5947H3.00309L1.76716 12.691C1.3028 12.3515 0.957613 11.874 0.780892 11.3265C0.604171 10.7791 0.604959 10.1899 0.783145 9.64294C0.961331 9.09601 1.3078 8.61937 1.77306 8.28111C2.23824 7.94291 2.79838 7.76034 3.37349 7.75945C3.3736 7.75945 3.37371 7.75945 3.37381 7.75945L7.7445 7.75945H8.10919L8.2206 7.4122L9.54833 3.27393L9.54837 3.27379C9.72483 2.72327 10.0716 2.24301 10.5386 1.90227C11.0056 1.56154 11.5688 1.37793 12.1469 1.37793C12.725 1.37793 13.2882 1.56154 13.7552 1.90227C14.2223 2.24301 14.569 2.72327 14.7455 3.27379L14.7455 3.27393L16.0732 7.4122L16.1847 7.75945H16.5493L20.924 7.75945C20.9241 7.75945 20.9243 7.75945 20.9244 7.75945C21.4995 7.76034 22.0596 7.94291 22.5248 8.28111C22.9901 8.61937 23.3365 9.09601 23.5147 9.64294C23.6929 10.1899 23.6937 10.7791 23.517 11.3265C23.3403 11.8739 22.9951 12.3514 22.5309 12.6909C22.5308 12.6909 22.5308 12.691 22.5307 12.691L18.9689 15.2923L18.6769 15.5056L18.788 15.8497L20.1408 20.039C20.3181 20.5883 20.3167 21.1797 20.1368 21.7281C19.9568 22.2765 19.6076 22.7538 19.1394 23.0912C18.6711 23.4287 18.1079 23.609 17.5307 23.6062C16.9535 23.6034 16.3921 23.4176 15.9271 23.0756L15.9269 23.0755L12.443 20.5151L12.1467 20.2973L11.8505 20.5153L8.36763 23.0787L8.36761 23.0786L8.36298 23.0821C7.90197 23.4298 7.34099 23.6191 6.76362 23.622C6.18625 23.6249 5.6234 23.4411 5.15894 23.0982L5.15902 23.098L5.14954 23.0914C4.67695 22.759 4.32443 22.2829 4.14451 21.7339C3.9646 21.1849 3.96693 20.5924 4.15117 20.0448L4.1512 20.0448L4.15308 20.039L5.50582 15.8497L5.61694 15.5056L5.32491 15.2923L3.00043 13.5947Z"
          stroke="#FFCE2F"
        />
      </svg>
    </div>
  );
}

export default StarSubmit;
