import React from 'react';

function Star() {
  return (
    <div className="Star icon">
      <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.856438 7.87178L3.08266 9.49768L2.2372 12.116C2.10057 12.5221 2.09884 12.9615 2.23226 13.3686C2.36569 13.7758 2.62713 14.1289 2.9776 14.3754C3.32208 14.6297 3.73952 14.766 4.16773 14.7639C4.59594 14.7617 5.012 14.6213 5.35391 14.3635L7.53073 12.7613L9.70818 14.3616C10.052 14.6145 10.4672 14.7519 10.8941 14.754C11.3209 14.7561 11.7374 14.6227 12.0837 14.3732C12.43 14.1236 12.6883 13.7707 12.8213 13.3651C12.9544 12.9595 12.9554 12.5222 12.8243 12.116L11.9788 9.49768L14.205 7.87178C14.5484 7.62071 14.8037 7.26754 14.9344 6.86272C15.0651 6.4579 15.0645 6.02213 14.9327 5.61766C14.801 5.21319 14.5447 4.86071 14.2007 4.61056C13.8566 4.36041 13.4423 4.22538 13.0169 4.22478H10.2822L9.45241 1.63836C9.32192 1.23123 9.06549 0.876071 8.72012 0.62409C8.37474 0.372109 7.95826 0.236328 7.53073 0.236328C7.1032 0.236328 6.68672 0.372109 6.34135 0.62409C5.99597 0.876071 5.73955 1.23123 5.60905 1.63836L4.77922 4.22478H2.04709C1.6217 4.22538 1.20738 4.36041 0.863304 4.61056C0.519233 4.86071 0.263012 5.21319 0.13124 5.61766C-0.000531767 6.02213 -0.001115 6.4579 0.129574 6.86272C0.260263 7.26754 0.515539 7.62071 0.858939 7.87178H0.856438Z" fill="#FFCE2F"/>
      </svg>
    </div>
  );
}

export default Star;
