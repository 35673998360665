import React from "react";

function Tick() {
  return (
    <div className="Tick icon">
      <svg
        width="16"
        height="12"
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.205671 7.96003L3.25834 11.0127C4.11676 11.8708 5.50826 11.8708 6.36668 11.0127L15.7943 1.58507C16.0755 1.29397 16.0674 0.8301 15.7763 0.548963C15.4923 0.274695 15.0421 0.274695 14.7582 0.548963L5.33058 9.9766C5.04443 10.2626 4.5806 10.2626 4.29448 9.9766L1.24181 6.92393C0.950711 6.64279 0.486843 6.65086 0.205705 6.94196C-0.0685627 7.22592 -0.0685627 7.67608 0.205671 7.96003Z"
          fill="black"
        />
      </svg>
    </div>
  );
}

export default Tick;
