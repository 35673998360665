import React from 'react';

function Video() {
  return (
    <a href='https://www.youtube.com/channel/UCPawysViXuAin_QPJ17Y6mw' target="_blank" className="Video icon">
      <svg width="26" height="17" viewBox="0 0 26 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M25.177 2.78453C24.8813 1.76306 24.0101 0.958346 22.9037 0.685288C20.8984 0.189209 12.8571 0.189209 12.8571 0.189209C12.8571 0.189209 4.81607 0.189209 2.81073 0.685288C1.70435 0.958346 0.833124 1.76306 0.537424 2.78453C0 4.63652 0 8.49999 0 8.49999C0 8.49999 0 12.3637 0.537424 14.2154C0.833124 15.2369 1.70435 16.0418 2.81073 16.3147C4.81607 16.811 12.8571 16.811 12.8571 16.811C12.8571 16.811 20.8984 16.811 22.9037 16.3147C24.0101 16.0418 24.8813 15.2369 25.177 14.2154C25.7144 12.3637 25.7144 8.49999 25.7144 8.49999C25.7144 8.49999 25.7144 4.63652 25.177 2.78453Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2859 12.0617L16.9663 8.50006L10.2859 4.93799V12.0617Z" fill="#424242"/>
      </svg>
    </a>
  );
}

export default Video;
