import React from 'react';

function Duration() {
  return (
    <div className="Duration icon">
      <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.407 1.25H11.782V0.625C11.782 0.45924 11.7161 0.300269 11.5989 0.183058C11.4817 0.065848 11.3227 0 11.157 0C10.9912 0 10.8323 0.065848 10.715 0.183058C10.5978 0.300269 10.532 0.45924 10.532 0.625V1.25H5.53198V0.625C5.53198 0.45924 5.46613 0.300269 5.34892 0.183058C5.23171 0.065848 5.07274 0 4.90698 0C4.74122 0 4.58225 0.065848 4.46504 0.183058C4.34783 0.300269 4.28198 0.45924 4.28198 0.625V1.25H3.65698C2.82849 1.25099 2.03421 1.58055 1.44837 2.16639C0.862534 2.75222 0.532975 3.5465 0.531982 4.375L0.531982 11.875C0.532975 12.7035 0.862534 13.4978 1.44837 14.0836C2.03421 14.6694 2.82849 14.999 3.65698 15H12.407C13.2355 14.999 14.0298 14.6694 14.6156 14.0836C15.2014 13.4978 15.531 12.7035 15.532 11.875V4.375C15.531 3.5465 15.2014 2.75222 14.6156 2.16639C14.0298 1.58055 13.2355 1.25099 12.407 1.25ZM1.78198 4.375C1.78198 3.87772 1.97953 3.40081 2.33116 3.04917C2.68279 2.69754 3.1597 2.5 3.65698 2.5H12.407C12.9043 2.5 13.3812 2.69754 13.7328 3.04917C14.0844 3.40081 14.282 3.87772 14.282 4.375V5H1.78198V4.375ZM12.407 13.75H3.65698C3.1597 13.75 2.68279 13.5525 2.33116 13.2008C1.97953 12.8492 1.78198 12.3723 1.78198 11.875V6.25H14.282V11.875C14.282 12.3723 14.0844 12.8492 13.7328 13.2008C13.3812 13.5525 12.9043 13.75 12.407 13.75Z" fill="#4593FE"/>
          <path d="M8.03198 10.3125C8.54975 10.3125 8.96948 9.89277 8.96948 9.375C8.96948 8.85723 8.54975 8.4375 8.03198 8.4375C7.51422 8.4375 7.09448 8.85723 7.09448 9.375C7.09448 9.89277 7.51422 10.3125 8.03198 10.3125Z" fill="#4593FE"/>
          <path d="M4.90698 10.3125C5.42475 10.3125 5.84448 9.89277 5.84448 9.375C5.84448 8.85723 5.42475 8.4375 4.90698 8.4375C4.38922 8.4375 3.96948 8.85723 3.96948 9.375C3.96948 9.89277 4.38922 10.3125 4.90698 10.3125Z" fill="#4593FE"/>
          <path d="M11.157 10.3125C11.6747 10.3125 12.0945 9.89277 12.0945 9.375C12.0945 8.85723 11.6747 8.4375 11.157 8.4375C10.6392 8.4375 10.2195 8.85723 10.2195 9.375C10.2195 9.89277 10.6392 10.3125 11.157 10.3125Z" fill="#4593FE"/>
      </svg>
    </div>
  );
}

export default Duration;
