import React from "react";

function Download() {
  return (
    <div className="Download icon">
      <svg
        width="20"
        height="19"
        viewBox="0 0 20 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.46898 14.3466C9.3962 15.2744 10.8999 15.2749 11.8278 14.3477C11.8281 14.3473 11.8285 14.347 11.8288 14.3466L14.3709 11.8046C14.6641 11.4803 14.6389 10.9796 14.3146 10.6864C14.0126 10.4134 13.5529 10.4139 13.2515 10.6875L10.935 13.0047L10.9406 0.791691C10.9405 0.354432 10.5861 0 10.1489 0C9.71166 0 9.35723 0.354432 9.35723 0.791654L9.35011 12.9897L7.04636 10.6875C6.73701 10.3784 6.23567 10.3786 5.92654 10.6879C5.61742 10.9973 5.61761 11.4986 5.92695 11.8077L8.46898 14.3466Z"
          fill="white"
        />
        <path
          d="M18.8573 12.6665C18.42 12.6665 18.0656 13.0209 18.0656 13.4582V16.6248C18.0656 17.0621 17.7112 17.4165 17.274 17.4165H3.02393C2.5867 17.4165 2.23227 17.0621 2.23227 16.6248V13.4582C2.23227 13.021 1.87784 12.6665 1.44062 12.6665C1.00336 12.6665 0.648926 13.021 0.648926 13.4582V16.6248C0.648926 17.9365 1.71226 18.9998 3.02393 18.9998H17.2739C18.5856 18.9998 19.6489 17.9365 19.6489 16.6248V13.4582C19.6489 13.021 19.2945 12.6665 18.8573 12.6665Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export default Download;
