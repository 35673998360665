import React from 'react';

function Niveau() {
  return (
    <div className="Niveau icon">
      <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_1774_5212)">
          <path d="M8.41132 3.75C7.91403 3.75 7.43712 3.94754 7.08549 4.29917C6.73386 4.65081 6.53632 5.12772 6.53632 5.625V13.125C6.53632 13.6223 6.73386 14.0992 7.08549 14.4508C7.43712 14.8025 7.91403 15 8.41132 15C8.9086 15 9.38551 14.8025 9.73714 14.4508C10.0888 14.0992 10.2863 13.6223 10.2863 13.125V5.625C10.2863 5.12772 10.0888 4.65081 9.73714 4.29917C9.38551 3.94754 8.9086 3.75 8.41132 3.75Z" fill="#5BC1FC"/>
          <path d="M14.0363 0C13.539 0 13.0621 0.197544 12.7105 0.549175C12.3589 0.900805 12.1613 1.37772 12.1613 1.875V13.125C12.1613 13.6223 12.3589 14.0992 12.7105 14.4508C13.0621 14.8025 13.539 15 14.0363 15C14.5336 15 15.0105 14.8025 15.3621 14.4508C15.7138 14.0992 15.9113 13.6223 15.9113 13.125V1.875C15.9113 1.37772 15.7138 0.900805 15.3621 0.549175C15.0105 0.197544 14.5336 0 14.0363 0V0ZM14.6613 13.125C14.6613 13.2908 14.5955 13.4497 14.4783 13.5669C14.361 13.6842 14.2021 13.75 14.0363 13.75C13.8706 13.75 13.7116 13.6842 13.5944 13.5669C13.4772 13.4497 13.4113 13.2908 13.4113 13.125V1.875C13.4113 1.70924 13.4772 1.55027 13.5944 1.43306C13.7116 1.31585 13.8706 1.25 14.0363 1.25C14.2021 1.25 14.361 1.31585 14.4783 1.43306C14.5955 1.55027 14.6613 1.70924 14.6613 1.875V13.125Z" fill="#5BC1FC"/>
          <path d="M2.78632 7.5C2.28903 7.5 1.81212 7.69754 1.46049 8.04917C1.10886 8.40081 0.911316 8.87772 0.911316 9.375L0.911316 13.125C0.911316 13.6223 1.10886 14.0992 1.46049 14.4508C1.81212 14.8025 2.28903 15 2.78632 15C3.2836 15 3.76051 14.8025 4.11214 14.4508C4.46377 14.0992 4.66132 13.6223 4.66132 13.125V9.375C4.66132 8.87772 4.46377 8.40081 4.11214 8.04917C3.76051 7.69754 3.2836 7.5 2.78632 7.5Z" fill="#5BC1FC"/>
          </g>
          <defs>
          <clipPath id="clip0_1774_5212">
          <rect width="15" height="15" fill="white" transform="translate(0.911316)"/>
          </clipPath>
          </defs>
      </svg>
    </div>
  );
}

export default Niveau;
