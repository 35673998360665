import React from "react";

function Tiktok() {
  return (
    <a href="https://www.tiktok.com/@mathappy_official" target="_blank"  className="Tiktok icon">
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.4873 7.80404C15.8433 8.69864 17.5044 9.225 19.2984 9.225V6.03867C18.9589 6.03874 18.6202 6.00606 18.2881 5.9411V8.4492C16.4942 8.4492 14.8333 7.92284 13.477 7.02831V13.5307C13.477 16.7835 10.6199 19.4202 7.09583 19.4202C5.7809 19.4202 4.55872 19.0533 3.54346 18.4241C4.70221 19.5176 6.31818 20.196 8.10597 20.196C11.6303 20.196 14.4875 17.5592 14.4875 14.3063V7.80404H14.4873ZM15.7337 4.58942C15.0408 3.89069 14.5858 2.98771 14.4873 1.98942V1.57959H13.5299C13.7709 2.84841 14.5929 3.93242 15.7337 4.58942ZM5.77246 15.928C5.3853 15.4594 5.17608 14.8863 5.17701 14.297C5.17701 12.8092 6.4838 11.603 8.09603 11.603C8.39649 11.6029 8.69515 11.6454 8.98149 11.7292V8.47169C8.64686 8.42937 8.30914 8.4114 7.97156 8.41799V10.9535C7.68501 10.8696 7.3862 10.8271 7.08567 10.8273C5.47344 10.8273 4.16673 12.0334 4.16673 13.5214C4.16673 14.5734 4.8199 15.4843 5.77246 15.928Z" fill="#555555"/>
        <path d="M13.4768 7.02824C14.8331 7.92277 16.494 8.44913 18.2879 8.44913V5.94104C17.2866 5.74418 16.4001 5.2612 15.7336 4.58942C14.5927 3.93235 13.7707 2.84835 13.5297 1.57959H11.0148V14.3061C11.0091 15.7898 9.70451 16.9911 8.09574 16.9911C7.14772 16.9911 6.3055 16.5741 5.77211 15.928C4.81962 15.4843 4.16644 14.5734 4.16644 13.5214C4.16644 12.0336 5.47316 10.8274 7.08538 10.8274C7.39428 10.8274 7.692 10.8718 7.97128 10.9536V8.41805C4.50907 8.48407 1.72461 11.0951 1.72461 14.3062C1.72461 15.9092 2.418 17.3624 3.54339 18.4241C4.55865 19.0533 5.78083 19.4203 7.09576 19.4203C10.62 19.4203 13.4769 16.7834 13.4769 13.5307V7.02824H13.4768Z" fill="white"/>
        <path d="M18.288 5.94107V5.2629C17.385 5.26417 16.4997 5.03077 15.7336 4.58939C16.4118 5.27468 17.3048 5.7472 18.288 5.94107ZM13.5298 1.57962C13.5068 1.45837 13.4891 1.33631 13.4769 1.21379V0.803955H10.0044V13.5306C9.99881 15.0141 8.69433 16.2154 7.08542 16.2154C6.61307 16.2154 6.16709 16.1119 5.77214 15.9281C6.30554 16.5741 7.14776 16.9911 8.09578 16.9911C9.7044 16.9911 11.0092 15.7899 11.0148 14.3062V1.57962H13.5298ZM7.97146 8.41809V7.69613C7.6813 7.65952 7.38876 7.64115 7.09587 7.64129C3.57139 7.64129 0.714355 10.2781 0.714355 13.5306C0.714355 15.5698 1.83722 17.3669 3.5435 18.424C2.41811 17.3623 1.72472 15.9091 1.72472 14.3062C1.72472 11.0951 4.5091 8.48411 7.97146 8.41809Z" fill="#888888"/>
      </svg>
    </a>
  );
}

export default Tiktok;
